import React from 'react'

const UnreadCount = ({ count }) => {
  if (count <= 0) {
    return null
  }

  return (
    <div className="h-6 w-6 rounded-full bg-alert text-white flex items-center justify-center font-bold text-sm text-center absolute bottom-3 left-4">
      {count > 99 ? '99' : count}
    </div>
  )
}

export default UnreadCount
