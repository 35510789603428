import React from 'react'
import useTopics from '../../../lib/useTopics'
import TopicPill from '../../topics/TopicPill'
import Icon from '../Icon'
import Loading from '../Loading'
import SearchInput from '../SearchInput'
import { Popout, PopoutContent, PopoutTrigger } from '../popout/Popout'

export default function SearchMenu() {
  const { items: topics, isValidating } = useTopics({ items: 3 })

  return (
    <Popout
      zIndex={999}
      placement="bottom"
      popoutClassName="bg-white rounded-xl border border-divider shadow w-72 max-w-full p-4"
    >
      <PopoutTrigger
        button={(visible) => (
          <Icon name="search" color={visible ? 'primary' : 'black'} size={30} />
        )}
      />
      <PopoutContent>
        <SearchInput
          name="search"
          placeholder="Search Community Lab"
          icon="search"
          small
        />
        {isValidating ? (
          <Loading />
        ) : (
          <div className="flex flex-col mt-4 overflow-x-hidden">
            <span className="text-sm uppercase font-bold mb-2">
              Suggested Topics
            </span>
            {topics?.map((topic, i) => (
              <div key={i} className="w-56">
                <TopicPill name={topic?.name} color={topic.color} />
              </div>
            ))}
          </div>
        )}
      </PopoutContent>
    </Popout>
  )
}
