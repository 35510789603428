import HelpButton from '../common/nav/HelpButton'
import NavBar from '../common/nav/NavBar'
import OneSignal from '../OneSignal'

export default function Layout({ children, backgroundColor = 'bg-alt-bg' }) {
  return (
    <div
      className={`h-full flex flex-col overflow-x-hidden overscroll-x-none ${backgroundColor}`}
    >
      <NavBar />
      <OneSignal />
      <div className={`content-container h-max flex-grow ${backgroundColor}`}>
        {children}
      </div>
      <HelpButton />
    </div>
  )
}
