import { formatDistanceToNowStrict } from 'date-fns'
import Image from 'next/image'
import Link from 'next/link'
import useMemoizedPathFor from '../../lib/pathFor'
import useUser from '../../lib/useUser'
import CLAvatar from '../common/CLAvatar'
import AttendanceCreatedNotification from './AttendanceCreatedNotification'
import BadgingAcceptedNotification from './BadgingAcceptedNotification'
import BadgingRequestedNotification from './BadgingRequestedNotification'
import DirectMessageNotification from './DirectMessageNotification'
import EventCreatedGroupNotification from './EventCreatedGroupNotification'
import GroupModeratorRequestedNotification from './GroupModeratorRequestedNotification'
import MembershipCreatedNotification from './MembershipCreatedNotification'
import PostCreatedGroupNotification from './PostCreatedGroupNotification'
import PostFollowedNotification from './PostFollowedNotification'
import PostMentionNotification from './PostMentionNotification'
import PostReplyNotification from './PostReplyNotification'
import Request from './Request'
import StampAwardedNotification from './StampAwardedNotification'
import WatercoolerMessageNotification from './WatercoolerMessageNotification'
import WeaveAcceptedNotification from './WeaveAcceptedNotification'
import WeaveRequestedNotification from './WeaveRequestedNotification'

export default function Notification({ notification }) {
  const Content =
    NotificationComponents[notification?.notifiable_type][notification?.action]
  const url = useUrlForNotification(notification)

  if (Content == null || notification?.notifiable == null) {
    return null
  }

  return (
    <Link href={url} className="w-full bg-alt-bg flex flex-col p-4 rounded-xl">
      <div className="flex flex-row">
        <NotificationLeft
          key={`notification-sent-${notification?.sent_at}`}
          notification={notification}
        />
        <div className="flex flex-col text-offBlack text-sm flex-1 ml-2 line-clamp-2 break-word">
          <Content notification={notification} />
          <p className="mt-1 text-disabled line-clamp-2 break-word">
            {formatDistanceToNowStrict(new Date(notification?.sent_at), {
              addWeaveSuffix: true,
            })}
          </p>
          {notification.request && <Request request={notification.request} />}
        </div>
      </div>
    </Link>
  )
}

function useUrlForNotification(notification) {
  const { user } = useUser()

  let resourceName = null
  let resource = null

  if (notification?.action === 'reply') {
    switch (notification?.notifiable?.postable_type) {
      case 'Post':
      case 'Event':
        resourceName = notification?.notifiable?.postable_type
        resource = notification?.notifiable?.postable_slug
        break
      default:
        resourceName = null
        resource = null
    }
  } else if (notification?.action === 'mention') {
    switch (notification?.notifiable?.postable_type) {
      case 'Post':
        resourceName = notification?.notifiable?.postable_type
        resource = notification?.notifiable?.postable_slug
        break
      case 'Chat':
        resourceName = 'chat'
        resource = notification?.notifiable?.postable_slug
        break
      case 'Event':
        resourceName = 'event'
        resource =
          notification?.notifiable?.postable_slug ||
          notification?.notifiable?.event_slug
        break
      case 'Group':
        resourceName = notification?.notifiable_type
        resource = notification?.notifiable?.slug
        break
      case 'Moderator':
        resourceName = 'group'
        resource = notification?.notifiable?.slug
        break
      default:
        resourceName = null
        resource = null
    }
  } else if (
    notification?.action === 'message' ||
    notification?.action === 'watercooler'
  ) {
    resourceName = 'chat'
    resource = notification?.notifiable?.postable_slug
  } else if (notification?.action === 'followed') {
    if (!!notification?.notifiable?.postable_type === true) {
      resourceName = notification?.notifiable?.postable_type
      resource = notification?.notifiable?.postable_slug
    }
    resourceName = notification?.notifiable_type
    resource = notification?.notifiable_slug
  } else {
    switch (notification?.notifiable_type) {
      case 'Post':
      case 'Event':
        resourceName = notification?.notifiable_type
        resource = notification?.notifiable
        break
      case 'Weave':
        resourceName = notification?.notifiable_type
        resource =
          user.id === notification?.notifiable?.sender?.id
            ? notification?.notifiable?.recipient
            : notification?.notifiable?.sender
        break
      case 'Badging':
        resourceName = 'badge'
        resource = notification?.notifiable?.badge
        break
      case 'Membership':
        resourceName = 'group'
        resource = notification?.notifiable?.group_slug
        break
      case 'Attendance':
        resourceName = 'event'
        resource = notification?.notifiable?.event_slug
        break
      case 'Stamping':
        resourceName = notification?.notifiable?.stampable_type
        resource = notification?.notifiable?.stampable_id
        break
      default:
        resourceName = null
        resource = null
    }
  }

  const url = useMemoizedPathFor(resourceName, resource)

  return url
}

function NotificationLeft({ notification }) {
  const { user } = useUser()

  if (user == null) {
    return null
  }

  let avatar = notification?.notifiable?.user?.avatar
  if (notification.notifiable_type === 'Weave') {
    avatar =
      user.id === notification.notifiable.sender.id
        ? notification.notifiable.recipient.avatar
        : notification.notifiable.sender.avatar
  }

  if (
    notification.notifiable_type === 'Membership' ||
    notification.notifiable_type === 'Attendance'
  ) {
    avatar = notification.notifiable?.avatar
  }

  if (
    notification?.notifiable?.image &&
    notification.notifiable_type === 'Event'
  ) {
    return (
      <div
        key={`${notification?.notifiable?.name}-event-image`}
        className="w-9 h-9 flex-shrink-0 rounded-full"
      >
        <Image
          key={`${notification?.notifiable?.name}-event-image`}
          src={notification?.notifiable?.image}
          width={30}
          height={30}
          className="rounded-full"
          style={{
            maxWidth: 30,
            maxHeight: 30,
          }}
          alt={`${notification?.notifiable?.user?.name} avatar`}
        />
      </div>
    )
  }

  if (avatar != null) {
    return (
      <div
        key={`${notification.notifiable?.name}-notification-avatar`}
        className="w-9 h-9 flex-shrink-0"
      >
        <CLAvatar
          key={`${notification.notifiable?.name}-notification-avatar`}
          avatar={avatar}
          size={30}
          name={notification.notifiable?.name}
        />
      </div>
    )
  }

  if (notification.notifiable_type === 'Badging') {
    return (
      <div
        key={`${notification.notifiable?.badge?.logo}-badge-avatar`}
        className="w-9 h-9 flex-shrink-0"
      >
        <CLAvatar
          key={`${notification.notifiable?.badge?.logo}-badge-avatar`}
          avatar={notification.notifiable?.badge?.logo}
          size={30}
          name={notification.notifiable?.badge?.name}
        />
      </div>
    )
  }

  if (notification.notifiable_type === 'Stamping') {
    return (
      <div
        key={`${notification.notifiable?.stamp_logo}-stamp-avatar`}
        className="w-9 h-9 flex-shrink-0"
      >
        <CLAvatar
          key={`${notification.notifiable?.stamp_logo}-stamp-avatar`}
          avatar={notification.notifiable?.stamp_logo}
          size={30}
          name={notification.notifiable?.stamp_name}
        />
      </div>
    )
  }

  return null
}

const NotificationComponents = {
  Post: {
    mention: PostMentionNotification,
    reply: PostReplyNotification,
    followed: PostFollowedNotification,
    created: PostCreatedGroupNotification,
    watercooler: WatercoolerMessageNotification,
    message: DirectMessageNotification,
  },
  Event: {
    created: EventCreatedGroupNotification,
  },
  Weave: {
    requested: WeaveRequestedNotification,
    accepted: WeaveAcceptedNotification,
  },
  Badging: {
    requested: BadgingRequestedNotification,
    accepted: BadgingAcceptedNotification,
  },
  Membership: {
    created: MembershipCreatedNotification,
    moderator: GroupModeratorRequestedNotification,
  },
  Attendance: {
    created: AttendanceCreatedNotification,
  },
  Stamping: {
    created: StampAwardedNotification,
  },
}
