import usePaginated from './api/usePaginated'

export default function useTopics(filters = { items: 10 }) {
  const { items, ...attributes } = usePaginated(
    () => `/api/topics`,
    (response) => response?.topics,
    filters,
    {
      refreshInterval: 3600000,
      dedupingInterval: 3600000,
    }
  )

  return {
    items,
    ...attributes,
  }
}
