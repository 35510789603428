export default function PostReplyNotification({ notification }) {
  return (
    <>
      <p className="w-full max-w-200 md:max-w-300 truncate overflow-hidden">
        <span className="text-off-black line-clamp-1 font-bold">
          {notification.notifiable?.user?.name}
        </span>{' '}
        replied to a post you follow
      </p>
      <p className="text-disabled line-clamp-2 break-word">
        {notification.notifiable?.snippet}
      </p>
    </>
  )
}
