import { useCallback, useMemo } from 'react'
import useSWRInfinite, { unstable_serialize as unstableSerialize } from 'swr/infinite'
import fetchJson from '../fetchJson'
import querystring from '../querystring'

const PAGE_SIZE = parseInt(process.env.NEXT_PUBLIC_PAGE_SIZE, 10) || 20

export default function usePaginated(getUrl, dataFromResponse, query, swrOpts) {
  if (query == null) {
    // eslint-disable-next-line no-param-reassign
    query = {}
  }

  const urlForIndex = useCallback(
    (index) => {
      const url = getUrl()
      if (url == null) {
        return null
      }

      // Waiting for all query params to be available
      // (could be a chain of requests loading)
      // e.g. user_id on a feed
      const blankQueries = Object.values(query).filter((val) => val == null)
      if (blankQueries.length > 0) {
        return null
      }

      query.page = index + 1
      query.items = query?.items || PAGE_SIZE
      // eslint-disable-next-line import/no-named-as-default-member
      return `${url}?${querystring.stringify(query)}`
    },
    [Object.values(query)]
  )

  const { error, data, isLoading, mutate, isValidating, size, setSize } = useSWRInfinite(
    (index) => urlForIndex(index),
    fetchJson,
    swrOpts
  )

  const isLoadingMore =
    isLoading ||
    (size > 0 && data && typeof data[size - 1] === 'undefined')
  const isEmpty = data?.[0] == null || dataFromResponse(data[0]) == null

  // We determine if the end is reached based on us having
  // received as many items as we asked for.
  // We memoize this in order to preserve the state if the user
  // deletes an item within this page and we're not at the end.
  const isReachingEnd = useMemo(() => {
    if (isEmpty) {
      return true
    }

    const lastPage = data[data.length - 1]
    return lastPage == null || dataFromResponse(lastPage).length < query.items
  }, [isLoading, isLoadingMore, size, Object.values(query)])

  if (!Array.isArray(data)) {
    return {}
  }

  const items = data?.map((page) => dataFromResponse(page)).flat()

  return {
    data, // array of pages
    items, // just the items from the pages
    isValidating,
    error,
    mutate,
    get cacheKey() {
      return unstableSerialize(() => urlForIndex(0))
    },
    isLoading,
    isLoadingMore,
    isReachingEnd,
    loadMore: () => {
      if (!isReachingEnd && !isValidating) {
        setSize(size + 1)
      }
    },
  }
}
