import useNotifications from '../../lib/useNotifications'
import useRequestCrud from '../../lib/useRequestCrud'
import Button from '../common/Button'

export default function Request({ request }) {
  const { mutate } = useNotifications()
  const { updateStatus } = useRequestCrud(request.id)

  async function onStatusChange(e, status) {
    e.preventDefault()
    try {
      await updateStatus(status)
      mutate()
    } catch (err) {
      console.error(err)
    }
  }

  if (request.status !== 'pending') {
    return (
      <div className="capitalize text-right text-disabled">
        {request.status}
      </div>
    )
  }

  return (
    <div className="flex flex-row justify-end gap-2 lg:gap-6 mt-2">
      <Button
        onClick={(e) => onStatusChange(e, 'accepted')}
        primary
        small
        noPadding
        className="px-6 py-1"
      >
        Accept
      </Button>
      <Button
        onClick={(e) => onStatusChange(e, 'ignored')}
        small
        noPadding
        className="px-6 py-1"
        white
      >
        Ignore
      </Button>
    </div>
  )
}
