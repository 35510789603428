import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  autoUpdate,
  flip,
  limitShift,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useMergeRefs,
  useRole,
} from '@floating-ui/react'
import { createContext, forwardRef, useContext, useMemo, useState } from 'react'

export function usePopout({
  initialOpen = false,
  placement = 'bottom',
  padding = 20,
  open: controlledOpen,
  onOpenChange: setControlledOpen,
  popoutClassName,
  zIndex = 100,
}) {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen)

  const open = controlledOpen ?? uncontrolledOpen
  const setOpen = setControlledOpen ?? setUncontrolledOpen

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      flip({
        fallbackAxisSideDirection: 'start',
        padding,
      }),
      shift({ padding, limiter: limitShift() }),
    ],
  })

  const { context } = data

  const click = useClick(context, {
    enabled: controlledOpen == null,
  })
  const dismiss = useDismiss(context)
  const role = useRole(context)

  const interactions = useInteractions([click, dismiss, role])

  return useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      popoutClassName,
      zIndex,
    }),
    [open, setOpen, interactions, data, popoutClassName, zIndex]
  )
}

const PopoutContext = createContext(null)

export const usePopoutContext = () => {
  const context = useContext(PopoutContext)

  if (context == null) {
    throw new Error('Popout components must be wrapped in <Popout />')
  }

  return {
    ...context,
    popoutClassName: context.popoutClassName,
    zIndex: context.zIndex,
  }
}

export function Popout({ children, popoutClassName, zIndex, ...restOptions }) {
  const popout = usePopout({ popoutClassName, zIndex, ...restOptions })
  return (
    <PopoutContext.Provider value={popout}>{children}</PopoutContext.Provider>
  )
}

export const PopoutTrigger = forwardRef(function PopoutTrigger(
  { children, asChild = false, button, ...props },
  propRef
) {
  const context = usePopoutContext()
  const childrenRef = children?.ref
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef])
  const buttonClass = context.className || 'cursor-pointer'

  const Button = button ? button(context.open) : children

  return (
    <span
      ref={ref}
      role="button"
      className={`${buttonClass} select-none focus:outline-none`}
      // The user can style the trigger based on the state
      data-state={context.open ? 'open' : 'closed'}
      {...context.getReferenceProps({
        ...props,
        onClick(e) {
          e.stopPropagation()
          e.preventDefault()
          if (context.onClick) {
            context.onClick()
          }
        },
      })}
    >
      {Button}
    </span>
  )
})

export const PopoutContent = forwardRef(function PopoutContent(props, propRef) {
  const { context: floatingContext, ...context } = usePopoutContext()
  const ref = useMergeRefs([context.refs.setFloating, propRef])

  const popoutClass =
    context.popoutClassName ||
    'bg-white rounded-xl border border-divider shadow p-2 w-60 border-box max-w-full overflow-y-scroll max-h-full select-none focus:outline-none'

  return (
    <FloatingPortal>
      <FloatingOverlay
        lockScroll
        style={{
          zIndex: context.zIndex,
          visibility: floatingContext.open ? undefined : 'hidden',
        }}
      >
        <FloatingFocusManager context={floatingContext} modal={false}>
          <div
            ref={ref}
            style={{
              top: 0,
              left: 0,
              ...context.floatingStyles,
              ...props.style,
              
            }}
            className={`${popoutClass} select-none focus:outline-none`}
            {...context.getFloatingProps(props)}
          >
            {props.children}
          </div>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  )
})
