import useMemoizedPathFor from '../../lib/pathFor'
import Icon from '../common/Icon'
import LinkPill from '../common/LinkPill'

export default function TopicPill({ name, color, alt, onClick, active }) {
  const topicHref = useMemoizedPathFor('topic', name)
  const rawColor = active ? 'white' : `topic-${color}`

  return (
    <LinkPill
      href={onClick == null ? topicHref : null}
      onClick={onClick == null ? null : () => onClick({ name, color })}
      LeftComponent={<Icon name="hashtag" color={rawColor} size={20} />}
      alt={alt}
      className={active ? 'text-white' : `text-topic-${color}`}
      active={active}
    >
      {name}
    </LinkPill>
  )
}
