export default function Paginated({
  isLoadingMore,
  isLoadingInitialData,
  isReachingEnd,
  loadMore,
  LoadMoreComponent,
  EmptyComponent,
  reverse,
  children,
}) {
  const output = [
    children,
    (children == null || children.length === 0) && EmptyComponent,
    !isReachingEnd && !isLoadingInitialData && (
      <LoadMoreComponent
        disabled={isLoadingMore}
        loading={isLoadingMore}
        onClick={loadMore}
      />
    ),
  ]

  return reverse ? output.reverse() : output
}
