export default function GroupModeratorRequestedNotification({ notification }) {
  return (
    <>
      <p className="w-full max-w-200 md:max-w-300 line-clamp-2 overflow-hidden">
        <span className="text-off-black font-bold">
          {notification?.notifiable?.user_name}
        </span>{' '}
        want’s to become a moderator of{' '}
        <span className="text-off-black font-bold">
          {notification?.notifiable?.group_name}
        </span>
      </p>
    </>
  )
}
