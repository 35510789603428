import React from 'react'
import links from '../../../config/links'
import options from '../../../config/options'
import LinkButton from '../LinkButton'
import BaseModal from '../modal/BaseModal'

function HelpMenu({ modalVisible, onRequestClose }) {
  const privacyNoticeAlertExpiry = new Date(options.privacyNoticeAlertExpiry)

  return (
    <BaseModal
      title="Help and support"
      closeIcon
      isOpen={modalVisible}
      onRequestClose={onRequestClose}
    >
      <LinkButton url={links.help} isExternalLink label="Get help" />
      <LinkButton url={links.feedback} isExternalLink label="Submit feedback" />
      <LinkButton
        url={links.supportGroup}
        isExternalLink
        label="Contact support"
      />
      <hr />
      <LinkButton
        url={links.privacy}
        isExternalLink
        noticeText={new Date() < privacyNoticeAlertExpiry ? 'Updated' : null}
        label="Privacy policy"
      />
      <LinkButton
        url={links.contributorTerms}
        isExternalLink
        label="Contributor terms"
      />
      <LinkButton
        url={links.communityGuidelines}
        isExternalLink
        label="Community guidelines"
      />
    </BaseModal>
  )
}

export default HelpMenu
