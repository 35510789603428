import Image from 'next/image'
import Link from 'next/link'
import { useState } from 'react'
import Avatar from 'react-avatar'
import useMemoizedPathFor from '../../lib/pathFor'

export default function CLAvatar({
  key,
  slug,
  type = 'user',
  avatar,
  name,
  size = 48,
  className,
  priority = false,
}) {
  const avatarHref = useMemoizedPathFor(type, slug)
  const classes = `flex-shrink-0 rounded-full object-cover ${className} leading-gone`

  const [imageComponent, setImageComponent] = useState(
    avatar != null && avatar?.length > 0 ? (
      <Image
        key={key || `${name}-cl-avatar-image`}
        src={avatar}
        alt={`${name} avatar`}
        width={size}
        height={size}
        className={classes}
        priority={priority}
        onError={() => {
          setImageComponent(
            <Avatar
              key={key || `${name}-cl-avatar`}
              alt={`${name} avatar`}
              name={name}
              size={size}
              className={classes}
              maxInitials={2}
              cache={{ cachePrefix: `${name}-cl-avatar` }}
            />
          )
        }}
        style={{
          maxWidth: size,
          height: size,
        }}
      />
    ) : (
      <Avatar
        key={key || `${name}-cl-avatar`}
        alt={`${name} avatar`}
        name={name}
        size={size}
        className={classes}
        maxInitials={2}
        cache={{ cachePrefix: `${name}-cl-avatar` }}
      />
    )
  )

  if (slug == null) {
    return imageComponent
  }

  return (
    <Link href={avatarHref} className={classes}>
      {imageComponent}
    </Link>
  )
}
