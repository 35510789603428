export default {
  privacy: 'https://www.communitylab.app/privacy-statement',
  communityGuidelines: 'https://www.communitylab.app/community-guidelines',
  mailToAndrew: 'mailto:andrew@communitylab.app',
  contributorTerms: 'https://www.communitylab.app/contributor-terms',
  twitter: 'https://twitter.com/communitylabapp',
  youtube: 'https://www.youtube.com/channel/UCnF00Q9Y_GgdFfPnRlacefw',
  feedback: 'https://community-lab.upvoty.com',
  help: 'http://community-lab.notion.site/Community-Lab-Help-Hub-302740ba4aca454096333517ae7d09dd',
  mapProvider: 'https://www.google.com/maps?q=',
  introVideo: 'https://www.youtube.com/watch?v=tGSN3Xf5luc',
  supportGroup:
    'https://app.communitylab.app/groups/help-hub?invite=49fEFB3AJx1VC8UdHhjJ9ZCi',
  vialogForm:
    'https://docs.google.com/forms/d/e/1FAIpQLSc-rfQHIOrfRoEmgtpl6UPD3auW1NGY1eD_h_5aNWpiwilr-g/viewform',
  notionHelp:
    'https://community-lab.notion.site/Having-trouble-joining-the-platform-6fa2229a14c341168bbd566dbcc2f53b',
  askAngusNotion:
    'https://community-lab.notion.site/Meet-Ask-Angus-78d3b823edb749779498b1a2486d40e2',
  andrewProfile: 'https://app.communitylab.app/users/andrew-barrie-host-4249',
  andrewProfileId: '2', // andrews production ID
  andrewEmail: 'mailto:andrew@communitylab.app',
  supportEmail: 'mailto:support@communitylab.app',
  clCluster: 'https://app.communitylab.app/badges/community-lab-team',
  groupAnalyticsInquiry:
    'https://docs.google.com/forms/d/e/1FAIpQLSeCMhzxkrMZcbD4F2xNTZV1eD5vurAXBOJFstAw58b5P94yTw/formrestricted',
  clusterAnalyticsInquiry:
    'https://docs.google.com/forms/d/e/1FAIpQLSeIuuldJpbp66D7wf9SY-MifKOJNceMxrElAgsF5qqQCvGAUg/viewform',
  openToAiForm:
    'https://docs.google.com/forms/d/e/1FAIpQLSern5cWCIE3zbMV9JT3s-m5kN1vLznVbKqgSXjIAZKfbwF7WQ/viewform?usp=sf_link',
  videoCompressionGuide: 'https://vimeo.com/blog/post/how-to-compress-a-video/',
  sponsorForm:
    'https://docs.google.com/forms/d/e/1FAIpQLSdP2yM8FETGEBU58g-x5ScXFq_8wuXGdPoZ1TaDsk-U-2d4PA/viewform?usp=sf_link',
  eventSupportForm:
    'https://docs.google.com/forms/d/e/1FAIpQLSdSPtJD2W0qi5kHwOLmQyW-VUJbTrY5guWfquZWuToCLqg2hQ/viewform',
  ecoSystemPackageForm:
    'https://docs.google.com/forms/d/e/1FAIpQLSe1VdqE4XnsXuzgz5C6lvkewOO-tyyA2kzhkXeXEURr6soyyg/viewform',
  onlineLearningForm:
    'https://docs.google.com/forms/d/e/1FAIpQLScsvena_qoeF3165YD-kaeSEv2XoYbl4drY5TYyu0S6tpyIkg/viewform',
  communityBuildingForm:
    'https://docs.google.com/forms/d/e/1FAIpQLSfQ96dg5gYKWQV60iinpeOr_XSzzDCJYsR9Bx6kl628mzkfPw/viewform?usp=send_form',
  videoForm:
    'https://docs.google.com/forms/d/e/1FAIpQLSeCMYzw6xKxass6yxgRTqwwbOoMfLzxkRygv2LJAqTb_BZJLw/viewform',
  customIntegrationForm:
    'https://docs.google.com/forms/d/e/1FAIpQLSc4K76onGnmuJXplfjJ1MdAArxnEXao4aYBYtoKnNiTcFneMw/viewform',
}
