export default function EventCreatedGroupNotification({ notification }) {
  return (
    <>
      <p className="w-full max-w-200 md:max-w-300 line-clamp-2 overflow-hidden">
        <span className="text-off-black font-bold">
          {notification.notifiable?.name}
        </span>{' '}
        Event has been created for{' '}
        <span className="text-off-black line-clamp-1 font-bold">
          {notification.notifiable?.eventable_name}
        </span>
      </p>
      <p className="text-disabled line-clamp-2 break-word">
        {notification.notifiable?.snippet}
      </p>
    </>
  )
}
