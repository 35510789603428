import React, { useState } from 'react'
import options from '../../../config/options'
import HelpModal from './HelpModal'

function HelpButton() {
  const [modalVisible, setModalVisible] = useState(false)

  // show dot until
  const dotDate = new Date(options.privacyNoticeAlertExpiry)
  const showDot = new Date() < dotDate

  return (
    <>
      <button
        onClick={() => setModalVisible(true)}
        tabIndex="0"
        className="w-10 h-10 hidden lg:block fixed rounded-full justify-center items-center
        shadow-lg bottom-8 right-4 z-100 focus:outline-none hover:bg-gray-100 hover:shadow-2xl"
      >
        ?
        {showDot && (
          <div className="absolute w-2 h-2 bg-red-500 rounded-full top-1 right-0" />
        )}
      </button>
      <HelpModal
        modalVisible={modalVisible}
        onRequestClose={() => setModalVisible(false)}
      />
    </>
  )
}

export default HelpButton
