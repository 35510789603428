import Link from 'next/link'
import React, { useState } from 'react'
import { useSWRConfig } from 'swr'
import links from '../../../config/links'
import options from '../../../config/options'
import useMemoizedPathFor from '../../../lib/pathFor'
import useUser from '../../../lib/useUser'
import { useCacheKey } from '../../context/CacheKey'
import CLAvatar from '../CLAvatar'
import Icon from '../Icon'
import { Popout, PopoutContent, PopoutTrigger } from '../popout/Popout'
import PopoutButton from '../popout/PopoutButton'
import HelpModal from './HelpModal'

export default function UserAvatarMenu() {
  const cacheKeyContext = useCacheKey()
  const { mutate } = useSWRConfig()
  const { logout, user } = useUser({ redirectTo: '/login' })
  const profileLink = useMemoizedPathFor('user', user?.slug)
  const [modalVisible, setModalVisible] = useState(false)

  const handleLogout = async () => {
    await logout()

    const clearCache = () =>
      mutate(cacheKeyContext.value, undefined, { revalidate: true })

    clearCache()
  }

  // show dot until
  const privacyNoticeAlertExpiry = new Date(options.privacyNoticeAlertExpiry)
  const showDot = new Date() < privacyNoticeAlertExpiry
  return (
    <Popout zIndex={999}>
      <PopoutTrigger asChild>
        <CLAvatar
          key={`${user?.name}-uam-avatar`}
          avatar={user?.avatar}
          name={user?.name}
          size={36}
          priority
        />
        {!user.mfa_enabled && (
          <div className="absolute w-2 h-2 bg-topic-blue rounded-full bottom-3 right-3" />
        )}
      </PopoutTrigger>
      <PopoutContent>
        {!user.mfa_enabled && (
          <Link href="/settings">
            <PopoutButton info>
              <Icon name="alert" color="topic-blue" size={22} />
              MFA not enabled
            </PopoutButton>
          </Link>
        )}
        {!user.is_pro && (
          <Link href={links.ecoSystemPackageForm}>
            <PopoutButton>Be an Ecosystem Builder</PopoutButton>
          </Link>
        )}
        <Link href={profileLink}>
          <PopoutButton>My profile</PopoutButton>
        </Link>
        <Link href="/settings">
          <PopoutButton>Settings</PopoutButton>
        </Link>
        <PopoutButton onClick={() => setModalVisible(true)}>
          <div className="flex flex-row items-center justify-center">
            Help and support
            {showDot && (
              <div className="w-2 h-2 bg-red-500 rounded-full ml-1" />
            )}
          </div>
        </PopoutButton>
        <PopoutButton onClick={handleLogout}>Log out</PopoutButton>
        <HelpModal
          modalVisible={modalVisible}
          onRequestClose={() => setModalVisible(false)}
        />
      </PopoutContent>
    </Popout>
  )
}
