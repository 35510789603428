import fetchJson from './fetchJson'

export default function useRequestCrud(requestId) {
  return {
    async updateStatus(status) {
      await fetchJson(`/api/requests/${requestId}.json`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          request: {
            status,
          },
        }),
      })
    },
  }
}
