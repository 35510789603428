import classnames from 'classnames'
import Link from 'next/link'
import { useAnalytics } from '../context/AnalyticsProvider'

export default function LinkPill({
  href,
  onClick,
  LeftComponent,
  children,
  alt,
  active,
  badge,
  isPrivate,
  className,
}) {
  const { track } = useAnalytics()

  const disabled = onClick == null && href == null

  const classes = classnames(
    `flex flex-row items-center px-3 h-8 rounded-full text-sm w-full max-w-full`,
    {
      'bg-alt-bg': alt && !active,
      'hover:bg-opacity-60': alt && !active && !disabled,
      'bg-white hover:bg-alt-bg': !alt && !active && !badge && !isPrivate,
      'hover:bg-alt-bg': !alt && !active && !disabled && !badge,
      'bg-disabled text-white': active,
      'text-off-black': !active,
      'cursor-pointer': !disabled,
      'bg-black hover:bg-black bg-opacity-70 hover:bg-opacity-100 text-white':
        badge,
      'text-topic-blue text-sm bg-topic-blue bg-opacity-20 pr-4 cursor-default':
        isPrivate,
    },
    className
  )

  const Wrapper = ({ children }) => (
    <>
      {href == null ? (
        <button
          onClick={(e) => {
            if (onClick) {
              onClick(e)
              track('link_pill_clicked', {
                text: typeof children === 'string' ? children : '',
              })
            }
          }}
          className={classes}
        >
          {children}
        </button>
      ) : (
        <Link
          href={href}
          onClick={() =>
            track('url_clicked', {
              url: href,
              text: typeof children === 'string' ? children : '',
            })
          }
          className={classes}
        >
          {children}
        </Link>
      )}
    </>
  )

  return (
    <Wrapper>
      {LeftComponent}
      <span
        className={classnames('line-clamp-1', {
          'ml-0.5': LeftComponent != null,
        })}
      >
        {children}
      </span>
    </Wrapper>
  )
}
