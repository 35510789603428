export default function BadgingAcceptedNotification({ notification }) {
  return (
    <>
      <p className="w-full max-w-200 md:max-w-300 line-clamp-2 overflow-hidden">
        <span className="text-off-black font-bold">
          {notification.notifiable?.badge?.name}
        </span>{' '}
        has been accepted
      </p>
    </>
  )
}
