import produce from 'immer'
import { useEffect, useRef } from 'react'
import usePaginated from './api/usePaginated'
import useSocket from './api/useSocket'
import useUser from './useUser'

export default function useNotifications(shouldFetch = true) {
  const { items, ...attributes } = usePaginated(
    () => shouldFetch ? '/api/notifications' : null,
    (response) => response?.notifications,
    {},
    {
      refreshInterval: 30000,
      dedupingInterval: 30000,
    }
  )

  // Prepend realtime notification updates
  const { mutate } = attributes
  const { user } = useUser()
  const receivedNotificationRef = useRef()
  useEffect(() => {
    receivedNotificationRef.current = (notification) => {
      mutate(
        produce((pages) => {
          if (pages == null) {
            return
          }
          if (pages.length === 0) {
            pages[0] = { notifications: [] }
          }

          pages[0].notifications.unshift(JSON.parse(notification))
        }),
        false
      )
    }
  }, [mutate])
  useSocket(
    `user-notifications-${user?.id}`,
    {
      channel: 'UserChannel',
      id: user?.id,
    },
    {
      received: (msg) => {
        if (msg?.event === 'app_notification') {
          receivedNotificationRef.current?.(msg.data)
        }
      },
    }
  )

  return { notifications: items, ...attributes }
}
