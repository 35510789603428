import classnames from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import isPinnedApp from '../../../lib/isPinnedApp'
import useUser from '../../../lib/useUser'
import Button from '../Button'
import Icon from '../Icon'
import UnreadCount from '../UnreadCount'
import SearchMenu from './SearchMenu'
import UserAvatarMenu from './UserAvatarMenu'
import UserNotificationsMenu from './UserNotificationsMenu'

export default function NavBar() {
  const { user, isLoading } = useUser()

  const wrapperStyle = classnames('w-full nav-bar', {
    'h-14': !isPinnedApp(),
    'h-20 md:14': isPinnedApp(),
  })

  return (
    <div className={wrapperStyle}>
      <header className="h-full flex flex-row bg-white px-3 justify-between items-center border-t mb:border-0 md:border-t-0 md:drop-shadow">
        <Brand />
        {user && <MainMenu />}
        {user && <Aside />}
        {!user && !isLoading && <Cta />}
      </header>
    </div>
  )
}

function Brand() {
  return (
    <Link href="/" className="hidden md:block w-1/3 cursor-pointer">
      <Image
        key='nav-bar-brand-image'
        src="/cl-nav-brand.png"
        alt="Community Lab Logo"
        width={42}
        height={45}
        priority
      />
    </Link>
  )
}

function MainMenu() {
  const { user } = useUser()

  return (
    <div className="flex flex-row items-start justify-around md:justify-center space-x-8 w-full md:w-1/3 h-full z-10">
      <MenuItem
        ActiveComponent={() => (
          <Icon name="home" size={32} color="topic-green" />
        )}
        InactiveComponent={() => <Icon name="home" size={32} />}
        href="/"
        exact
        title="Home"
      />
      <MenuItem
        ActiveComponent={() => (
          <Icon name="discover" size={32} color="topic-green" />
        )}
        InactiveComponent={() => <Icon name="discover" size={32} />}
        href="/discover"
        dataCy="navbar-discover"
        title="Discover"
      />
      <MenuItem
        ActiveComponent={() => (
          <Icon name="your-lab" size={32} color="topic-green" />
        )}
        InactiveComponent={() => <Icon name="your-lab" size={32} />}
        href="/lab"
        route="/lab"
        title="Your Lab"
      />
      <MenuItem
        ActiveComponent={() => (
          <Icon name="chat" size={32} color="topic-green" />
        )}
        InactiveComponent={() => <Icon name="chat" size={32} />}
        href="/chats"
        unreadCount={user?.unread_messages_count}
        title="Chats"
      />
    </div>
  )
}

function MenuItem({
  ActiveComponent,
  InactiveComponent,
  href,
  route,
  dataCy,
  exact,
  unreadCount,
  title,
}) {
  const router = useRouter()
  const isActive =
    router.route === route || exact
      ? router.route === href
      : router.route.startsWith(href)

  const component = isActive ? <ActiveComponent /> : <InactiveComponent />
  const wrapperClass = classnames(
    'px-4 h-14 items-center border-b-4 center flex',
    {
      'border-topic-green': isActive,
      'border-white': !isActive,
    }
  )

  return (
    <Link href={href} className={wrapperClass} data-cy={dataCy} title={title}>
      <div className="relative">
        {unreadCount > 0 && <UnreadCount count={unreadCount} />}
        {component}
      </div>
    </Link>
  )
}

function Aside() {
  return (
    <div className="hidden md:flex h-full w-1/3 flex-row justify-end space-x-6 items-center">
      <div>
        <SearchMenu />
      </div>
      <div>
        <UserNotificationsMenu />
      </div>
      <div className="leading-gone">
        <UserAvatarMenu />
      </div>
    </div>
  )
}

function Cta() {
  return (
    <div className="pt-2 md:pt-0 flex h-full w-full md:w-1/3 flex-row justify-center space-x-6 items-center">
      <Button primary tiny href="/login">
        Login
      </Button>
      <Button outline primary tiny href="/signup">
        Signup
      </Button>
    </div>
  )
}
