import { isObject } from 'lodash'
import { useMemo } from 'react'

export function pathFor(resourceName, resource) {
  if (resourceName == null) {
    return '/'
  }

  const identifier = getIdentifier(resource)

  if (identifier == null) {
    return '/'
  }

  switch (resourceName?.toLowerCase()) {
    case 'user':
    case 'weave':
      return `/users/${identifier}`
    case 'group':
      return `/groups/${identifier}`
    case 'topic':
      return `/topics/${identifier}`
    case 'post':
      return `/posts/${identifier}`
    case 'event':
      return `/events/${identifier}`
    case 'badge':
      return `/badges/${identifier}`
    case 'chat':
      return `/chats/${identifier}`
    case 'resource':
      return `/resources/${identifier}`
    case 'attendees':
      return `/events/${identifier}/attendees`
    default:
      return '/'
  }
}

function getIdentifier(resource) {
  if (isObject(resource)) {
    return resource.slug || resource.id
  }

  return resource
}

export default function useMemoizedPathFor(resourceName, resource) {
  return useMemo(() => pathFor(resourceName, resource), [resourceName, resource])
}
