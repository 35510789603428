/* eslint-disable no-param-reassign */
import qs from 'querystring'

const defaultOptions = {
  omitEmpty: true,
}

/*
 * Same as querystring.stringify, but removes empty params by default
 *
 * Example:
 * stringify({x: 3, role: null, name: 'Dave'})
 * => 'x=3&name=Dave'
 *
 * stringify({x: 3, role: null, name: 'Dave'}, {omitEmpty: false})
 * => 'x=3&role=&name=Dave'
 */
export function stringify(obj, options) {
  options = { ...defaultOptions, ...options }
  if (options.omitEmpty) {
    obj = omitEmpty(obj)
  }

  return qs.stringify(obj)
}

function omitEmpty(obj) {
  return Object.keys(obj).reduce((acc, k) => {
    const v = obj[k]
    if (v != null && v !== '') {
      acc[k] = v
    }
    return acc
  }, {})
}

export default { stringify }
