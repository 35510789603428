/* eslint-disable no-undef */
import Script from 'next/script'
import { useEffect, useState } from 'react'
import useUser from '../lib/useUser'

const OneSignal = () => {
  const { user, updateUser } = useUser()
  const [oneSignalSDKLoaded, setOneSignalSDKLoaded] = useState(false)

  useEffect(() => {
    if (!oneSignalSDKLoaded) {
      return
    }

    window.OneSignalDeferred = window.OneSignalDeferred || []
    if (!OneSignalDeferred) {
      console.warn('OneSignal SDK not loaded.')
      return
    }

    OneSignalDeferred.push(async function (OneSignal) {
      if (user?.slug) {
        await OneSignal.login(user.slug)
      }

      if (user !== null && updateUser !== null) {
        await updateUserOneSignalId(OneSignal, user, updateUser)
      }

      OneSignal.User.PushSubscription.addEventListener('change', async () => {
        updateUserOneSignalId(OneSignal, user, updateUser)
      })
    })

    return () => {
      OneSignalDeferred.push(async function (OneSignal) {
        OneSignal.User.PushSubscription.removeEventListener('change')
      })
    }
  }, [oneSignalSDKLoaded, user?.slug, updateUser])

  return (
    <>
      <Script
        id="onesignal-sdk"
        src="https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js"
        defer
        onLoad={() => initOneSignal(setOneSignalSDKLoaded, user, updateUser)}
      />
    </>
  )
}

const initOneSignal = async (setOneSignalSDKLoaded, user, updateUser) => {
  window.OneSignalDeferred = window.OneSignalDeferred || []
  if (!OneSignalDeferred) {
    console.warn('OneSignal SDK not loaded.')
    return
  }

  OneSignalDeferred.push(async function (OneSignal) {
    try {
      await OneSignal.init({
        appId: process.env.NEXT_PUBLIC_ONESIGNAL_APPID,
        safari_web_id: process.env.NEXT_PUBLIC_ONESIGNAL_SAFARIID,
        notifyButton: {
          enable: false,
        },
        promptButton: { showCredit: false },
        allowLocalhostAsSecureOrigin: process.env.NODE_ENV === 'development',
        serviceWorkerParam: {
          scope: '/js/push/onesignal/',
        },
        serviceWorkerPath: './js/push/onesignal/OneSignalSDKWorker.js',
        serviceWorkerUpdaterPath:
          '/js/push/onesignal/OneSignalSDKUpdaterWorker.js',
      })

      setOneSignalSDKLoaded(true)
    } catch (error) {
      console.error('An unexpected error happened initializing OneSignal:', error)
      setOneSignalSDKLoaded(false)
    }
  })
}

async function updateUserOneSignalId(OneSignal, user, updateUser) {
  if (OneSignal.User.PushSubscription.optedIn) {
    if (user?.one_signal_id !== OneSignal.User.PushSubscription.id) {
      try {
        await updateUser({
          ...user,
          one_signal_id: OneSignal.User.PushSubscription.id,
        })
      } catch (error) {
        console.error('An unexpected error happened:', error)
      }
    }
  }
}

export default OneSignal
