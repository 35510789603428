export default function WeaveRequestedNotification({ notification }) {
  return (
    <>
      <p className="w-full max-w-200 md:max-w-300 truncate overflow-hidden">
        <span className="text-off-black font-bold">
          {notification.notifiable?.sender?.name}
        </span>{' '}
        sent you a weave request
      </p>
    </>
  )
}
