export default function DirectMessageNotification({ notification }) {
  return (
    <>
      <p className="w-full max-w-200 md:max-w-300 line-clamp-2 overflow-hidden">
        <span className="text-off-black font-bold">
          {notification.notifiable?.user?.name}
        </span>{' '}
        has sent a message
      </p>
      <p className="text-disabled line-clamp-2 break-word">
        {notification.notifiable.snippet}
      </p>
    </>
  )
}
