import classnames from 'classnames'
import Button from '../Button'
import Loading from '../Loading'
import Paginated from './Paginated'

export default function PaginatedWithButton({
  isLoadingMore,
  isLoadingInitialData,
  isReachingEnd,
  loadMore,
  children,
  buttonClassName,
  EmptyComponent,
  buttonAlt,
  LoadMoreComponent = null,
}) {
  return (
    <Paginated
      isLoadingMore={isLoadingMore}
      isLoadingInitialData={isLoadingInitialData}
      isReachingEnd={isReachingEnd}
      loadMore={loadMore}
      EmptyComponent={EmptyComponent}
      LoadMoreComponent={(props) =>
        LoadMoreComponent ? (
          <LoadMoreComponent {...props} />
        ) : (
          <Button
            className={classnames(
              'flex flex-row items-center py-2 px-8 mx-auto',
              buttonClassName
            )}
            noPadding
            primary
            alt={buttonAlt}
            {...props}
          >
            <span>{props.loading ? 'Loading...' : 'Load More'}</span>
            {props.loading && (
              <span className="ml-2">
                <Loading small color={buttonAlt ? 'topic-green' : 'white'} />
              </span>
            )}
          </Button>
        )
      }
    >
      {children}
    </Paginated>
  )
}
