import Link from 'next/link'
import useNotifications from '../../../lib/useNotifications'
import usePopoutTriggerRef from '../../../lib/usePopoutTriggerRef'
import useUser from '../../../lib/useUser'
import Notification from '../../notifications/Notification'
import EmptyView from '../EmptyView'
import Icon from '../Icon'
import UnreadCount from '../UnreadCount'
import PaginatedWithButton from '../pagination/PaginatedWithButton'
import { Popout, PopoutContent, PopoutTrigger } from '../popout/Popout'

export default function UserNotificationsMenu({ isMobile = false }) {
  const { popoutTriggerRef, popoutIsOpen } = usePopoutTriggerRef()
  const { notifications, ...pagination } = useNotifications(popoutIsOpen)
  const { user, markNotificationsAsRead } = useUser()

  const handleMarkNotificationsRead = async () => {
    try {
      await markNotificationsAsRead()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Popout
      zIndex={999}
      placement="bottom"
      popoutClassName={`bg-white rounded-xl border border-divider shadow max-w-screen w-80 lg:w-96 overflow-hidden h-5/6 md:h-3/6 max-h-screen pb-4 ${
        isMobile ? 'block md:hidden' : 'hidden md:block'
      }`}
    >
      <PopoutTrigger
        ref={popoutTriggerRef}
        button={(visible) => (
          <div
            className="relative"
            onClick={handleMarkNotificationsRead}
            onKeyDown={handleMarkNotificationsRead}
            role="button"
            tabIndex="0"
            aria-label="Notifications"
          >
            <UnreadCount count={user?.unread_notifications_count} />
            <Icon name="bell" color={visible ? 'primary' : 'black'} size={30} />
          </div>
        )}
      />
      <PopoutContent>
        <div className="flex-row flex justify-between align-middle p-4 mb-2 border-b border-divider">
          <h3 className="flex flex-row justify-between font-bold">
            NOTIFICATIONS
          </h3>
          <Link href="/settings">
            <Icon
              name="settings"
              className="cursor-pointer"
              color="primary"
              size={20}
            />
          </Link>
        </div>
        <div className="px-4 space-y-4 h-full overflow-y-scroll scrollbar-hide pb-20">
          <PaginatedWithButton
            {...pagination}
            EmptyComponent={<EmptyView title="No notifications yet" />}
          >
            {notifications?.map((item, i) => (
              <Notification key={i} notification={item} />
            ))}
          </PaginatedWithButton>
        </div>
      </PopoutContent>
    </Popout>
  )
}
