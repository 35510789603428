import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import Icon from './Icon'

export default function SearchInput({
  defaultTerm,
  name,
  placeholder,
  icon,
  small,
  fullWidth = false,
}) {
  const [searchTerm, setSearchTerm] = useState('')
  const router = useRouter()

  const searchStyle = !small
    ? 'h-14 w-full mx-auto pl-12 pr-10 rounded-full text-base text-disabled bg-white border-none z-0 focus:shadow focus:outline-none'
    : 'h-10 w-full mx-auto pl-9 pr-8 rounded text-base text-black bg-gray-100 border-none z-0 focus:outline-none'

  const iconStyle = !small ? 'absolute top-3 left-3' : 'absolute top-1 left-1'
  const clearButtonStyle = !small
    ? 'absolute top-3 right-4'
    : 'absolute top-1 right-1'

  const maxWidth = fullWidth ? 'max-w-700' : 'max-w-600'

  useEffect(() => {
    if (searchTerm != null) {
      return
    }

    setSearchTerm(defaultTerm || '')
  }, [defaultTerm])

  const handleClear = () => {
    setSearchTerm('')
  }

  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      const searchUrl = `/search?term=${searchTerm.replace('#', '')}`
      router.push(searchUrl)
    }
  }

  return (
    <div className={`w-full ${maxWidth} mx-auto`}>
      <div className="relative">
        <input
          name={name}
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          type="text"
          className={searchStyle}
          placeholder={placeholder}
          onKeyUp={handleKeyUp}
        />
        <div className={iconStyle}>
          <Icon name={icon} color="disabled" />
        </div>
        {searchTerm && (
          <button
            type="button"
            onClick={handleClear}
            className={clearButtonStyle}
            aria-label="Clear"
          >
            <Icon name="times" color="disabled" size={20} />
          </button>
        )}
      </div>
    </div>
  )
}
