/* eslint-disable camelcase */
export default function StampAwardedNotification({ notification }) {
  const { creator_name, stampable_type, stamp_name } = notification?.notifiable

  return (
    <>
      <p className="w-full max-w-200 md:max-w-300 line-clamp-2 overflow-hidden text-off-black font-bold">
        You got an Impact Stamp!
      </p>
      <p className="w-full max-w-200 md:max-w-300 line-clamp-2 overflow-hidden">
        {creator_name} has awarded your {stampable_type} with a '{stamp_name}'
        Stamp!
      </p>
    </>
  )
}
