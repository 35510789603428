import classnames from 'classnames'
import { forwardRef } from 'react'

function Loading({ children, small, color = 'primary' }, ref) {
  return (
    <div className="w-full h-full flex flex-col text-center" ref={ref}>
      <div
        className={classnames('lds-dual-ring mx-auto', `text-${color}`, {
          'lds-dual-ring-small my-0': small,
          'my-5': !small,
        })}
      />
      {children && <p>Loading: {children}</p>}
    </div>
  )
}

export default forwardRef(Loading)
