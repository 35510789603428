import Link from 'next/link'
import React from 'react'
import Icon from './Icon'

const LinkButton = ({
  isActive,
  icon,
  label,
  url,
  isExternalLink,
  noticeText,
}) => {
  const backgroundColor = isActive ? 'bg-white' : ''
  const fontWeight = isActive ? 'font-bold' : ''
  const iconColor = isActive ? 'primary' : null

  return (
    <Link
      href={url}
      className={`w-full flex-grow p-4 rounded-lg flex hover:bg-gray-200 flex-row justify-start items-center ${backgroundColor} cursor-pointer`}
    >
      <Icon name={icon} size={24} color={iconColor} />
      <p className={`pl-2 text-lg ${fontWeight}`}>{label}</p>
      <div className="ml-auto flex flex-row items-center">
        {noticeText && (
          <p className="mr-1 bg-topic-green rounded font-bold px-6 py-1 text-xs text-gray-100">
            {noticeText}
          </p>
        )}
        {isExternalLink && <Icon name="chevron-right" size={20} />}
      </div>
    </Link>
  )
}

export default LinkButton
