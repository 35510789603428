import { NextSeo } from 'next-seo'
import useUser from '../../lib/useUser'

export default function Meta({ title: inputTitle, ...props }) {
  const title = useTitle(inputTitle)

  return <NextSeo title={title} {...props} />
}

function useTitle(inputTitle) {
  const { isAuthed, user } = useUser()

  if (isAuthed && user != null && user.unread_notifications_count > 0) {
    return `(${user.unread_notifications_count}) ${inputTitle}`
  }

  return inputTitle
}
