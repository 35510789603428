import { useRef } from 'react'

export default function usePopoutTriggerRef() {
  const popoutTriggerRef = useRef(null)
  const popoutIsOpen =
    popoutTriggerRef?.current?.getAttribute('data-state')?.toLowerCase() ===
    'open'

  return {
    popoutTriggerRef,
    popoutIsOpen,
  }
}
